<template>
    <div class="heading-block" :class="CSSClasses">
        <Eyebrow
            v-if="eyebrow"
            hasUnderline
            class="eyebrow"
            :opacity="theme"
            :direction="direction"
            :isCentered="size === '100' ? true : false"
        >
            {{ eyebrow }}
        </Eyebrow>
        <Typography class="title" :variant="`${header.type}-display`" :as="header.type">{{
            header.headerTitle
        }}</Typography>
        <RichTextElements v-if="isDekNotEmpty(subheader)" :elements="subheader" bodyStyle="serif-large" class="dek" />
        <div class="buttons">
            <CarnegieButton
                class="button"
                v-for="button in buttons"
                :key="button.id"
                v-bind="button.button"
                :theme="theme"
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    eyebrow: {
        type: String,
    },
    buttons: {
        type: Array,
    },
    header: {
        type: Object,
    },
    subheader: {
        type: Object,
    },
    size: {
        ...sizes,
    },
});
const theme = inject('tierTheme', 'light');
const CSSClasses = computed(() => {
    return [`size-${props.size}`, `theme-${theme}`];
});
</script>

<style lang="scss" scoped>
.heading-block {
    .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin-top: 2.6rem;
    }
    .title {
        margin-bottom: 1.6rem;
    }
    .dek {
        color: color(noir, default, 0.7);
    }
    &.size-25 {
        .buttons {
            display: block;
            .button {
                display: block;
                width: fit-content;
                margin-bottom: 0.8rem;
            }
        }
    }
    &.size-33 {
        @include media-query(tablet-mw) {
            padding: 0 1.5rem;
        }
    }
    &.size-50 {
        @include media-query(tablet-mw) {
            padding: 0 2.5rem;
        }
    }
    &.size-100 {
        text-align: center;
        .title {
            max-width: 104rem;
        }
        .dek,
        .buttons {
            max-width: 60rem;
        }
        .title,
        .dek,
        .buttons {
            margin-left: auto;
            margin-right: auto;
        }
        .buttons {
            justify-content: center;
            margin-top: 1.6rem;
        }
    }
    &.theme-dark {
        .title {
            color: color(blanc);
        }
        .eyebrow,
        .dek {
            color: color(blanc, default, 0.7);
        }
    }
}
</style>
